<template>
	<section class="movie poster-all-container">

		<starposter v-for="(data,i) in movieList" :mediaitem="data" :key="i"></starposter>

		<div class="load-more-btn" @click="loadmore()">LOAD MORE</div>
	</section>
</template>

<script>
import starposter from "./star-poster.vue";
import { mapState } from "vuex";

export default {
	data() {
		return {
			backgroundImage: "",
			movieList: [],
			page: 1,
		};
	},
	computed: {
		...mapState({
			targetValue: (state) => state.homedata,
		}),
	},
	created() {
		if (this.$store.state.homedata == "success") {
			this.getMovieList()
		}
		this.$store.watch(
			(state, getters) => state.homedata,
			(newVal, oldVal) => {
				// 在这里处理状态变化
				console.log("targetValue 已经设置为", newVal);
				this.getMovieList()
			}
		);
	},

	components: {
		starposter,
	},
	methods: {
		changeBackgroundImage(imageUrl) {
			this.backgroundImage = `url(${imageUrl})`;
		},
		loadmore(){
			this.page += 1
			this.getMovieList()
		},
		getMovieList() {
			const options = {
				method: "GET",
				headers: {
					accept: "application/json",
					Authorization:
						"Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIyYzMzNjE2YTRjODNiN2Y2OTgyNDI3Njg5NjhhMGY3MyIsInN1YiI6IjY0NjRhYTUwOWYzN2IwMDE1Y2MzODdlYyIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ._XwSWCopAiYWATtv8Vl5w7KyEBJ25mpQf_bnd4Dyhpg",
				},
			};

			fetch(
				`https://api.themoviedb.org/3/discover/movie?include_adult=true&include_video=false&language=en-US&page=${this.page}&sort_by=popularity.desc`,
				options
			)
				.then((response) => response.json())
				.then((response) => {
					console.log(response);
					let newresule  = response.results
					this.movieList = this.movieList.concat(newresule)
				})
				.catch((err) => console.error(err));
		},
	},
};
</script>
